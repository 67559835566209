import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _27081b0e = () => interopDefault(import('../pages/account-settings.vue' /* webpackChunkName: "pages/account-settings" */))
const _0ed68952 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _97a4b164 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _12f7acbf = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _245a834d = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _47d1e572 = () => interopDefault(import('../pages/reset-link-sent.vue' /* webpackChunkName: "pages/reset-link-sent" */))
const _120dc7ef = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _26a41e65 = () => interopDefault(import('../pages/sign-up.vue' /* webpackChunkName: "pages/sign-up" */))
const _3bd78caa = () => interopDefault(import('../pages/terms-and-conditions.vue' /* webpackChunkName: "pages/terms-and-conditions" */))
const _2f636258 = () => interopDefault(import('../pages/vendor-registration.vue' /* webpackChunkName: "pages/vendor-registration" */))
const _d863ba38 = () => interopDefault(import('../pages/eoi/_id.vue' /* webpackChunkName: "pages/eoi/_id" */))
const _742873e0 = () => interopDefault(import('../pages/password-reset/_code.vue' /* webpackChunkName: "pages/password-reset/_code" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _3d6810e8,
    name: "index___en"
  }, {
    path: "/fr",
    component: _3d6810e8,
    name: "index___fr"
  }, {
    path: "/en/account-settings",
    component: _27081b0e,
    name: "account-settings___en"
  }, {
    path: "/en/change-password",
    component: _0ed68952,
    name: "change-password___en"
  }, {
    path: "/en/dashboard",
    component: _97a4b164,
    name: "dashboard___en"
  }, {
    path: "/en/forgot-password",
    component: _12f7acbf,
    name: "forgot-password___en"
  }, {
    path: "/en/privacy-policy",
    component: _245a834d,
    name: "privacy-policy___en"
  }, {
    path: "/en/reset-link-sent",
    component: _47d1e572,
    name: "reset-link-sent___en"
  }, {
    path: "/en/sign-in",
    component: _120dc7ef,
    name: "sign-in___en"
  }, {
    path: "/en/sign-up",
    component: _26a41e65,
    name: "sign-up___en"
  }, {
    path: "/en/terms-and-conditions",
    component: _3bd78caa,
    name: "terms-and-conditions___en"
  }, {
    path: "/en/vendor-registration",
    component: _2f636258,
    name: "vendor-registration___en"
  }, {
    path: "/fr/changer-le-mot-de-passe",
    component: _0ed68952,
    name: "change-password___fr"
  }, {
    path: "/fr/enregistrement-des-vendeurs",
    component: _2f636258,
    name: "vendor-registration___fr"
  }, {
    path: "/fr/mot-de-passe-oublie",
    component: _12f7acbf,
    name: "forgot-password___fr"
  }, {
    path: "/fr/parametres-du-compte",
    component: _27081b0e,
    name: "account-settings___fr"
  }, {
    path: "/fr/politique-de-confidentialite",
    component: _245a834d,
    name: "privacy-policy___fr"
  }, {
    path: "/fr/reinitialiser-lien-envoye",
    component: _47d1e572,
    name: "reset-link-sent___fr"
  }, {
    path: "/fr/s-identifier",
    component: _120dc7ef,
    name: "sign-in___fr"
  }, {
    path: "/fr/s-inscrire",
    component: _26a41e65,
    name: "sign-up___fr"
  }, {
    path: "/fr/tableau-de-bord",
    component: _97a4b164,
    name: "dashboard___fr"
  }, {
    path: "/fr/termes-et-conditions",
    component: _3bd78caa,
    name: "terms-and-conditions___fr"
  }, {
    path: "/en/EOI/:id",
    component: _d863ba38,
    name: "eoi-id___en"
  }, {
    path: "/en/password-reset/:code",
    component: _742873e0,
    name: "password-reset-code___en"
  }, {
    path: "/fr/EOI/:id",
    component: _d863ba38,
    name: "eoi-id___fr"
  }, {
    path: "/fr/reinitialisation-du-mot-de-passe/:code",
    component: _742873e0,
    name: "password-reset-code___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
